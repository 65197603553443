<template>
  <div class="user_setting main_top" >
    <div class="setting_container">
        <div class="info_title">
            {{ $t('key41') }}
            <div class="icon_back flex_center iconfont icon-back-line-free hidden-sm-and-up" @click="toBack()"></div>
        </div>
        <div class="info_list ">
            <div class="info_group flex">
                <div class="list_label">{{ $t('key42') }}</div>
                <div class="list_value">
                    <div class="user_avatar" @click="changeItem('upAvatarFlag')"><img :src="userInfo.image" alt=""></div>
                </div>
            </div>
            <div class="info_group flex">
                <div class="list_label">{{ $t('key43') }}</div>
                <div class="list_value" v-if="!nickNameFlag"> {{ userInfo.nickName }}</div>
                <div class="list_value" v-if="nickNameFlag">
                    <el-input v-model="userForm.nickName" size="mini" clearable></el-input>
                </div>
                <div class="list_tab" @click="nickNameFlag=true" v-if="!nickNameFlag">{{ $t('key32') }}</div>
                <div class="list_btn flex" v-if="nickNameFlag">
                    <div class="btn_cancel"  @click="nickNameFlag=false">{{ $t('key40') }}</div>
                    <div class="ben_Save" @click="modify()">{{ $t('key48') }}</div>
                </div>
            </div>
            <div class="info_group flex">
                <div class="list_label">{{ $t('key44') }}</div>
                <div class="list_value" v-if="userInfo.emailBindStatus==1"> {{ userInfo.name }}</div>
                <div class="list_value" v-if="userInfo.emailBindStatus!=1" style="color:#888">{{ $t('key696') }}</div>
                <div class="list_tab" @click="changeItem('upNameFlag')" v-if="userInfo.emailBindStatus!=1">{{ $t('key697') }}</div>
            </div>
            <div class="info_group flex">
                <div class="list_label">{{ $t('key45') }}</div>
                <div class="list_value" v-if="userInfo.threeType">{{ $t('key46') }}</div>
                <div class="list_tab" @click="changeItem('upPassFlag')" v-if="!userInfo.threeType">{{ $t('key32') }}</div>
            </div>
            
        </div>
        <div class="item_line hidden-xs-only"></div>
        
        <div class="third_login">
            <div class="item_name hidden-sm-and-up">{{ $t('key47') }}</div>
            <div v-for="(item,index) in thirdList" :key="index">
                <div class="third_group flex" v-if="item.type!=0">
                    <div class="third_name" >{{ item.name }}</div>
                    <div class="third_user" v-if="item.isBind">{{ item.userName }}</div>
                    <div class="third_hand" @click="bindThirdBtn(item)" v-if="!item.isBind">{{ $t('key47') }}</div>
                </div>    
            </div>
            
            
        </div>
    </div>
    <div class="pc_dialog hidden-xs-only" v-show="showDialog">
        <div class="dialog">
            <div class="dialog_container">
                <div class="dialog_thumb">
                    <div class="dialog_group" v-show="upPassFlag">         
                        <div class="dialog_title">{{ $t('key670') }}</div>
                        <!-- change password -->
                        <div class="dialog_content"  >
                            <el-form label-position="top" label-width="80px" :model="userForm" ref="ruleForm2" :rules="rules" >
                                <el-form-item label="" prop="oldPassword">
                                  <el-input v-model="userInfo.name" disabled type="text"></el-input>
                                </el-form-item> 
                                <el-form-item label="" prop="code">
                                    <div class="code_item flex_sb">                           
                                        <el-input v-model="userForm.code" :placeholder="$t('key691')"></el-input>
                                        <div class="code_img" @click="getCode()"><div class="code_btn">{{ $t('key693') }}</div></div>
                                    </div>                      
                                </el-form-item> 
                                <el-tag type="warning" style="margin-bottom:16px" v-if="sendCode">{{ $t('key694') }}</el-tag>
                                <el-form-item :label="$t('key45')" prop="oldPassword">
                                  <el-input v-model="userForm.oldPassword" :placeholder="$t('key692')" type="password"></el-input>
                                </el-form-item> 
                                <el-form-item :label="$t('key695')" prop="newPassword">
                                  <el-input v-model="userForm.newPassword" :placeholder="$t('key692')" type="password"></el-input>
                                </el-form-item>         
                            </el-form>
                             <div class="dialog_btn" @click="confirm()">{{ $t('key8') }}</div>
                        </div>   
                    </div>
                    <div class="dialog_group" v-show="upAvatarFlag">         
                        <div class="dialog_title">{{ $t('key643') }}</div>
                        <!-- change avatar -->
                        <div class="dialog_content">
                            <div class="">
                                <el-upload
                                    :action="upUrl+'prod_api/api/common/tencentUpload'"
                                    list-type="picture-card"
                                    :headers="config"
                                    :data="upData"
                                    :file-list="fileList"
                                    :on-success="(res,file,fileList)=>handleAvatarSuccess(res,file,fileList)"
                                    :before-upload="beforeAvatarUpload"
                                    :on-preview="handlePictureCardPreview"
                                    :on-exceed="exceedCount"
                                    :limit="1"
                                    :on-remove="(file,fileList)=>handleRemove(file,fileList)">
                                    <i class="el-icon-plus"></i>
                                
                                </el-upload>
                            </div>
                            <div class="dialog_btn" @click="modify()">{{ $t('key8') }}</div>
                        </div>   
                    </div>
                    <div class="dialog_group" v-show="bingThirdFlag">
                        <div class="dialog_title">{{ $t('key47') }}{{ thirdSignItem.name }}</div>
                        <div class="dialog_logo"><img src="../../assets/image/logo.png" alt=""></div>
                        <div class="dialog_text">{{ $t('key698') }}</div>
                        <div class="dialog_btn flex_center" v-show="thirdSignItem.type!='1'" :style="{background:thirdSignItem.bg}" @click="thirdBindMenth(thirdSignItem)">
                            <div class="flex_center iconfont icon_content " :class="thirdSignItem.icon" :style="{color:thirdSignItem.color}"></div>
                            <div class=""  >{{ thirdSignItem.name }}</div>
                        </div>
                        <div class="flex_center" v-show="thirdSignItem.type=='1'" style="margin-top: 40px;">
                            <div class="google_bind_sigin" id="google_bind_sigin"></div>
                        </div>
                    </div>
                    <div class="dialog_group" v-show="upNameFlag">
                        <div class="dialog_title">{{ $t('key47') }}{{ $t('key44') }}</div>
                        <div class="dialog_content"  >
                            <el-form label-position="top" label-width="80px" :model="signForm" ref="ruleForm3" :rules="rules2" >
                                <el-form-item label="" prop="email">
                                  <el-input v-model="signForm.email" :placeholder="$t('key689')"></el-input>
                                </el-form-item> 
                                <el-form-item label="" prop="code">
                                    <div class="code_item flex_sb">
                                        <el-input v-model="signForm.code" :placeholder="$t('key691')"></el-input>
                                        <div class="code_img" @click="getCode2()"><div class="code_btn">{{ $t('key693') }}</div></div>
                                    </div>                      
                                </el-form-item> 
                                <el-tag type="warning" style="margin-bottom:16px" v-if="sendCode">{{ $t('key694') }}</el-tag>
                                <el-form-item label="" prop="password">
                                  <el-input v-model="signForm.password" :placeholder="$t('key692')" type="password"></el-input>
                                </el-form-item> 
                                <el-form-item label="" prop="confirmPassword">
                                    <el-input v-model="signForm.confirmPassword" :placeholder="$t('key692')" type="password"></el-input>
                                </el-form-item> 
                            </el-form>
                             <div class="dialog_btn" @click="updataEmail()">{{ $t('key8') }}</div>
                        </div>  
                    </div>
                    <div class="btn_close iconfont icon-close" @click="showDialog=false"></div>
                </div>
              
            </div>
        </div>
    </div>           
</div>
</template>

<script>
import cookies from '../../utils/cookie';
import config_Api from '../../../vue.config'
import { thirdSign } from '../../mixins/thirdSign';
export default {
    mixins:[thirdSign],
    data(){
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
            callback(new Error(this.$t('key692')));
            } else if (value !== this.signForm.password) {
            callback(new Error(this.$t('key688')));
            } else {
            callback();
            }
        };
        var validateEmail = (rule, value, callback) => {
            let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (value === '') {
               callback(new Error(this.$t('key689')));
            } else if (!regex.test(value)) {
              callback(new Error(this.$t('key690')));
            } else {
              callback();
            }
        };
        return{
            thirdLogoin:[
                {name:'Google'},{name:'Facebook'},{name:'Line'},{name:'Twitter'},{name:'Apple'},{name:'TikTok'},{name:'VK'},
            ],
            rules:{
                oldPassword: [{ required: true, message: this.$t('key692'), trigger: 'blur' } ],
                newPassword: [  { required: true, message: this.$t('key692'), trigger: 'blur' } ],
                code: [{ required: true, message: this.$t('key691'), trigger: 'blur' }],
                email: [  { required: true, validator:validateEmail, trigger: 'blur' } ],
               
             
            },
            rules2:{
               
                code: [{ required: true, message: this.$t('key691'), trigger: 'blur' }],
                email: [  { required: true, validator:validateEmail, trigger: 'blur' } ],
                password: [  { required: true, message: this.$t('key692'), trigger: 'blur' } ],
                confirmPassword: [  { required: true, validator:validatePass2, trigger: 'blur' } ],
             
            },
            nickNameFlag:false,
            userForm:{
                avatar:'',
                nickName:'',
                oldPassword:'',
                newPassword:'',
                password:'',
                code:'',
                type:3,
                email:'',
                
            },
            signForm:{
                email:'',
                password:'',
                confirmPassword:'',
                code:'',
                type:'5',
                // 类型 1登录邮箱 2忘记密码邮箱 3修改密码邮箱 4注册邮箱 5绑定邮箱
                
            },
            showDialog:false,
            upAvatarFlag:false,
            fileList:[],
            upData:{},
            upPassFlag:false,
            upNameFlag:false,
            sendCode:false,
            bingThirdFlag:false,
            thirdSignItem:'',
            threeAccountList:[],
            thirdList:[],
            upUrl:"https://www.playsavefristofficial.com/"
            
        }
    },
    created(){
        // this.upUrl = config_Api.devServer.proxy
        console.log("api==",config_Api.devServer.proxy)
        this.getBindThird()
    },
    mounted(){
        this.getGoogle()
    },
    methods:{
        toBack(){
            this.$router.back()
        },
        modify(){
            let {avatar,nickName,oldPassword,newPassword,code} = this.userForm
            this.$axiosApi.updUserInfo({avatar,nickName,oldPassword,newPassword,code}).then(res=>{
                if(res.code == 200){
                    this.$message.success('success')
                    this.$store.dispatch('getUserInfo')
                    this.nickNameFlag = false
                    this.showDialog  =false
                    this.upAvatarFlag = false
                    this.upPassFlag = false
                    this.sendCode = false
                    this.bingThirdFlag = false
                    this.userForm={
                        avatar:'',
                        nickName:'',
                        oldPassword:'',
                        newPassword:'',
                        password:'',
                        code:'',
                        type:3,
                        email:'',
                    }
                }
            })
        },
        confirm(){
            this.$refs['ruleForm2'].validate((valid) => {
                if (valid) {
                    this.modify()
                }
            })
        },
        changeItem(name){
            this.showDialog = true
            this.upAvatarFlag = false
            this.upPassFlag = false
            this[name] = true
        },
        getCode(){
            let code = this.userForm.code
            let account = this.userInfo.name
            let password = this.userForm.password
            let type = this.userForm.type
            if(!account){
                this.$message.error(this.$t('key689'))
                return
            }
            this.$axiosApi.getCode({code,password,account,type}).then(res=>{
                if(res.code == 200){          
                    this.sendCode = true  
                }
            })
        },
        getCode2(){
            let code = this.signForm.code
            let account =this.signForm.email
            let password = this.signForm.password
            let type = this.signForm.type
            if(!account){
                this.$message.error(this.$t('key689'))
                return
            }
            this.$axiosApi.getCode({code,password,account,type}).then(res=>{
                if(res.code == 200){          
                    this.sendCode = true  
                }
            })
        },
        handleAvatarSuccess(res,file,fileList) {
            console.log('上传成功',res,file,fileList)
            if(res.code == 200){
            this.$message.success('success')
            this.userForm.avatar = res.url
            }else{
            this.$message.error('Upload failed')
            fileList.map((v,index)=>{
                if(v.uid == file.uid){
                fileList.splice(index,1)
                }
            })
            return
            }
        

        },
        beforeAvatarUpload(count,file,fileList) {
        // const isJPG = file.type === 'image/jpeg';
        // console.log(count,file,fileList)
        // const isLt2M = file.size / 1024 / 1024 < 2;

        // console.log('上传之前',file,fileList)
        // if (!isLt2M) {
        //   this.$message.error('The size of the uploaded image cannot exceed 2MB!');
        //   return false
        // }
        
        
        },
        handleRemove(item,file, fileList) {
        console.log(item,file, fileList);
    
        item.values.map((v,index)=>{
            if(file.response.data.objectId == v){
            item.values.splice(index,1)
            }
        })
        console.log('item',item)
        },
        handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
        },
        exceedCount(){
        this.$message.warning('Exceeding the limit quantity')
        return
        },
        bindThirdBtn(item){
            this.thirdSignItem = item
            this.bingThirdFlag = true
            this.showDialog = true
           
        },
        thirdBindMenth(item){
          this.handleClick(item,'bind')
            // this.handleCredentialResponse()
           
        },
       
        getBindThird(){
            this.$axiosApi.threeAccountList().then(res=>{
                if(res.code == 200){             
                    let threeAccountList = res.data
                    this.threeAccountList = threeAccountList
                    let logCate = this.logCate
                    logCate.map(v=>{
                        threeAccountList.map(c=>{
                            if(v.type == c.type){
                                v.isBind = true
                                v.userName = c.name
                            }else{
                                v.isBind = false
                            }
                        })
                        return v
                    })
                    this.thirdList = logCate
                    console.log('logCate',logCate)
                }
            })
        },
        updataEmail(){
            this.$refs['ruleForm3'].validate((valid) => {
                if (valid) {
                    let {email,password,confirmPassword,code} = this.signForm
                    this.$axiosApi.bindEmail({email,password,confirmPassword,code}).then(res=>{
                        if(res.code == 200){
                            this.$message.success('success')
                            this.$store.dispatch('getUserInfo')
                            this.upNameFlag = false
                            this.showDialog  =false
                        }
                    })
                }
            })
        }
    },
    computed:{
        userInfo(){this.userForm.nickName = this.$store.state.userInfo.nickName; return this.$store.state.userInfo},
        config(){return {'authorization':cookies.getToken()}}
    }
}
</script>

<style lang='scss' scoped>
// user
.user_setting{
    margin-top:16px;
  
    // margin-bottom:16px;
    .setting_container{
        background:#fff;
      
        min-height: 456px;
        border-radius: 4px;
    
        padding:24px;
       
    }
    .info_title{
        margin-bottom: 24px;
        color: #1f2128;
        letter-spacing: .5px;
        font-weight: 600;
        font-size: 20px;
    }
    .info_list{
        .info_group{
            margin-bottom:14px;
            text-align: left;
            .list_label{
                color: #888;
                width: 146px;
                font-size: 14px;
                line-height: 48px;
            }
            .list_value{
                line-height: 40px;
                font-size: 14px;
                color: #1f2128;
                .user_avatar{
                    width:48px;
                    height: 48px;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }
            .list_tab{
                line-height: 40px;
                font-size: 14px;
                color: #5e626b;
                cursor: pointer;
                margin-left:auto;
                
            }
            .list_btn{
                line-height: 40px;
                font-size: 14px;
                color: #5e626b;
                margin-left:auto;
                >div{
                    cursor: pointer;
                    &:hover{
                        opacity: 0.7;
                    }
                    &:first-child{
                        margin-right:16px;
                    }
                }
            }
        }
        
    }
    .item_line{
        margin: 40px 0;
        height: 1px;
        background: #ebecf0;
    }
    .third_login{
        .item_name{
            color:#1f2128;
            font-size: 15px;
            background:#e8e9ed;
            padding:8px;
            text-align: left;
        }
        .third_group{
            margin-bottom:32px;
            font-size:14px;
            color:#888;
            .third_name{
                width:120px;
                text-align: left;
            }
            .third_user{
                // margin-left:60px;
                color:#000;
            }
            .third_hand{
                margin-left:auto;
                color:#5e626b;
                cursor: pointer;
                &:hover{
                    opacity: 0.7;
                }
            }
                
            
        }
    }
}
/deep/.el-input__inner{
        background:#eff2f5;
        height: 38px;
        border:1px solid #eff2f5;
        box-sizing: border-box;
        width: 300px;
        &:focus{
        border:1px solid #ffd633;
        }
    }
    .dialog{
    position: fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background:#0000008f;
    z-index: 9999;
    .dialog_back{
        background: #fff;
        color: #0f0f0f;
        font-size: 20px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        position: relative;
        top:-14px;
        
    }
    .dialog_container{
      padding:16px 72px;
      width: 644px;
      max-width: 100%;
      margin:0 auto;
      position: relative;
      top:25%;
      
      .dialog_thumb{
        background:#fff;
        border-radius: 6px;
      }
      .dialog_group{ 
        background: 0 0 / 100% url(https://static.lootbar.gg.com/src/img/img_playsavefristofficial_bg.dabe53a4.png) no-repeat;
        border-radius: 6px;
        text-align: left;
        width: 100%;
        padding:24px 16px;
        position: relative;
        .dialog_title{
          color: #1f2128;
          margin-bottom: 16px;
          font-size: 24px;
          font-weight: 600;
        }
      
        .dialog_content{
            position: relative;
            .google_bind_sigin{
                width: 500px;
                max-width: 500px;
            }
            .code_item{
                .code_img{
                    width:50%;
                    padding:0 10px;
                    cursor: pointer;
                    .code_btn{
                        // padding:0 4px;
                        height: 46px;
                        border-radius: 4px;
                    
                        color:#77808c;
                        border:1px solid #d2d3d4;
                        cursor: pointer;
                        text-align: center;
                        line-height: 46px;
                        color:#000;
                        font-size: 14px;
                        background: #ffcb2e;
                        &:hover{
                            opacity: 0.7;
                        }
                    }
                }
            }
            /deep/.el-input__inner{
                background:#eff2f5;
                height: 48px;
                border:1px solid #eff2f5;
                box-sizing: border-box;
                width: 100%;
                &:focus{
                border:1px solid #ffd633;
                }
            }
            /deep/.el-form-item__label{
                padding:0;
            }
           
        }
         .dialog_text{
            font-size: 12px;
            line-height: 20px;
            margin-top: 12px;
            color:#77808c;
            text-align: center;
            .link_tab{
                cursor: pointer;
                // text-decoration: underline;
                &:hover{
                    text-decoration: underline;
                }
                // text-decoration-color: transparent;
            }
            .link_line{
                margin:0px 8px;
                color:#e8e9ed;
            }
            
            
          }
          
      }
      .btn_close{
        background:#fff;
        color:#0f0f0f;
        font-size: 24px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        position: absolute;
        top:16px;
        right:10px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        &:hover{
          opacity: 0.7;
        }
      }
    }
   
}
.dialog_btn{
        width: 420px;
        height: 44px;
        font-size: 16px;
        line-height: 44px;
        margin: 40px auto 0;
        font-weight: 700;
        cursor: pointer;
        background:#ffcb2e;
        border-radius: 6px;
        text-align: center;
        color:#fff;
        .icon_content{
            font-size: 24px;       
            margin-right:12px;         
         }
        &:hover{
            opacity: 0.7;
        }
    }
.dialog_logo{
    width: 150px;
    margin:20px auto; 
}
@media screen and (max-width:768px){
    .user_setting{
        margin-top:0!important;
    }
    .setting_container{
    
        padding:0 16px 0 !important;
        margin:0 -16px!important;
        .info_title{
          background:#ebecf0 ;
          height: 44px;
          margin:0 -16px;
          line-height: 44px;
          position: relative;
          .icon_back{
            font-size: 20px;
            position: absolute;
            left:16px;
            top:12px;
            height: 20px;
            cursor: pointer;
          }
        }
    }
    .info_list{
        padding-bottom:12px;
        .info_group{
            justify-content: space-between;
            border-bottom: 1px solid #ebecf0;
            padding: 6px 0;
            margin: 0 !important;
        }
    }
   
    .third_group{
        height:56px;
        margin:0!important;
    }
}

</style>